import React from 'react';
import './App.css';
import RequestEvent from "./components/requestEvent";
import MainMenu from "./components/mainmenu";
import EventPage from "./pages/event";
import MitarbeiterPage from "./pages/mitarbeiter";
import PageNotFound from "./pages/pageNotFound";
import DrupalPage from "./pages/page";
import GetConfigPage from "./util/ConfigPage";
import DefaultConfig from "./global_config";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Router from "./util/Router";

type AppState = {
  //node: any,
  //config_page: any,
  QueryRouter: any
}

class App extends React.Component<{}, AppState> {
  constructor(props: any) {
    super(props);
    // Setting up initial state
    this.state = {
      QueryRouter: null

      //node: null,
      //config_page: null
    }
  }

  async componentDidMount(){
    console.log(navigator.language);
    
    const QueryRouter = new Router();
    console.log(QueryRouter);
    await QueryRouter.ResolveUrl();
    this.setState({
      QueryRouter: QueryRouter
    });
    console.log(this.state.QueryRouter);
    console.log("done");

    /*
    // Render config page
    let config_page = await GetConfigPage("demo_translate_config", "include=field_bild");
    console.log(config_page);
    this.setState({
      config_page: config_page
    })
    */
  }

  render(){
    return(
      <div>
        {this.state.QueryRouter !== null && 
          <BrowserRouter>
            <MainMenu />
            <Routes>
              <Route path='/' element={<DrupalPage queryResult={this.state.QueryRouter} />}></Route>
              <Route path='/:slug' element={<DrupalPage />}></Route>
              <Route path='/event/:slug' element={<EventPage />}></Route>
              <Route path='/mitarbeiter/:slug' element={<MitarbeiterPage />}></Route>
              <Route path="*" element={<PageNotFound />}/>
            </Routes>
          </BrowserRouter>
        }
      </div>
      
      /*
      {this.state.QueryRouter && 
        <BrowserRouter>
        <MainMenu />
        <Routes>
          <Route path='/' element={<DrupalPage queryResult={this.state.QueryRouter} />}></Route>
          <Route path='/:slug' element={<DrupalPage />}></Route>
          <Route path='/event/:slug' element={<EventPage />}></Route>
          <Route path='/mitarbeiter/:slug' element={<MitarbeiterPage />}></Route>
          <Route path="*" element={<PageNotFound />}/>
        </Routes>
      </BrowserRouter>
      }
      */
      
    );
  }
}

export default App;
