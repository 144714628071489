import React from 'react';

class MitarbeiterPage extends React.Component<{},{}>{
  constructor(props: any){
    super(props);
  }

  render(): JSX.Element{
    return(
      <div>Mitarbeiter</div>
    );
  }
}

export default MitarbeiterPage;