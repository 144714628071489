import DefaultConfig from "./../global_config";

class Router{
  queryResult : any;
  queryStatus : null | number;
  bundle: string | null;
  uuid : string | null;

  constructor(){
    this.uuid = null;
    this.bundle = null;
    this.queryResult = {};
    this.queryStatus = null;
  }

  ResolveUrl(){
    return fetch(DefaultConfig.DrupalServerUrlFull + "/router/translate-path?path=" + window.location.pathname)
    .then(res => res.json())
    .then(
      (result) => {
        if(result.entity){
          this.uuid   = result.entity.uuid;
          this.bundle = result.entity.bundle;
          return this.getQueryResult();
        }
        else{
          this.queryStatus = 404;
        }
      },
      (error) => {
        this.queryStatus = 504;
        console.log(error);
      }
    )
  }

  getQueryResult(){
    return fetch(DefaultConfig.DrupalServerUrlFull + "/jsonapi/node/" + this.bundle + "/" + this.uuid)
    .then(res => res.json())
    .then(
      (result) => {
        this.queryResult = result;
        this.queryStatus = 200;
      },
      (error) => {
        this.queryStatus = 504;
        console.log(error);
      }
    );
  }
}

export default Router;