import React from 'react';
import MainMenuItem from "./mainmenuitem";
import DefaultConfig from "./../global_config";
import "../scss/navigation.scss";

type MainMenuState = {
  menu_items: JSX.Element[]
}

class MainMenu extends React.Component<{},MainMenuState>{
  constructor(props: any) {
    super(props);
    this.state = {
      menu_items: []
    }
  }

  componentDidMount(){
    fetch(DefaultConfig.DrupalServerUrlFull + "/jsonapi/singlemenu/main")
    .then(res => res.json())
    .then(
      (result) => {
        let items:JSX.Element[] = [];
        result.forEach((item: any, i:number) => {
          items.push(
            <MainMenuItem key={i} title={item.title} url={item.url} treeLevel="1" children={item.children} />
          );
        });
        this.setState({
          menu_items: items
        });
        
      },
      (error) => {
        console.log(error);
      }
    )
  }

  render(){

    return(
      <header role="navigation" className='navigation'>
        <div className='container'>
          <ul className='menu menu-main'>
            {this.state.menu_items}
          </ul>
        </div>
      </header>
    );
  }
}

export default MainMenu;