import React from 'react';
import {ParagraphMitarbeiterProps,ParagraphMitarbeiterState} from "./../../types/ParagraphMitarbeiterTypes";

class ParagraphMitarbeiter extends React.Component<ParagraphMitarbeiterProps,ParagraphMitarbeiterState>{
  constructor(props: any){
    super(props);
  }

  render(){
    return(
      <div className='paragraph-employee'>
        <img src={this.props.img} />
        <span className='employee-name'>{this.props.name}</span>
      </div>
    );
  }
}

export default ParagraphMitarbeiter;