import React from 'react';
import ParagraphRenderer from "./../util/ParagraphRenderer";
import Router from "../util/Router";
import withRouter from "./../util/withRouter";

type DrupalPageProps = {
  params: any
  QueryRouter: any
}

type DrupalPageState = {
  paragraphs: any[],
  //QueryRouter: any
}

class DrupalPage extends React.Component<DrupalPageProps,DrupalPageState>{
  constructor(props: any){
    super(props);
    this.state = {
      paragraphs: [],
      //QueryRouter: null,
    }
  }

  async componentDidMount(){
    console.log("mounted");
    if(typeof this.props.QueryRouter !== "undefined" && this.props.QueryRouter !== null){
      console.log(this.props.QueryRouter);
      this.renderContent();
    }
  }

  componentDidUpdate(prevProps: any){
    if(prevProps.params.slug !== this.props.params.slug){
      this.renderContent();
    }
  }

  async renderContent(){
    /*
    const QueryRouter = new Router();
    await QueryRouter.ResolveUrl();

    this.setState({
      QueryRouter: QueryRouter
    })
    */

    let paragraphs:any[]     = this.props.QueryRouter.queryResult.data.relationships.field_module.data;
    
    let renered_paragraph:any[] = []; 
    let i:number = 0;

    for(const paragraph of paragraphs){
      renered_paragraph.push(await ParagraphRenderer(paragraph, i));
      i++;
    }
    
    this.setState({
      paragraphs: renered_paragraph
    });
  }
  
  render(): React.ReactNode {
    return(
      <div>{this.state.paragraphs}</div>
    );
  }
}

export default withRouter(DrupalPage);