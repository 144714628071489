import React from 'react';
import { Link } from "react-router-dom"

type MainMenuItemProps = {
  title: string,
  url: string,
  treeLevel: string,
  children: object[]
}

type MainMenuItemState = {
  sub_menu_items: JSX.Element[]
}

class MainMenuItem extends React.Component<MainMenuItemProps,MainMenuItemState>{
  constructor(props: any) {
    super(props);

    this.state = {
      sub_menu_items: []
    }
  }

  componentDidMount(){
    let items:JSX.Element[] = [];
    this.props.children.forEach((item: any, i: number) => {
      items.push(
        <MainMenuItem key={i} title={item.title} url={item.url} treeLevel="1" children={item.children} />
      );
    });
    this.setState({
      sub_menu_items: items
    });
  }
  
  render(){
    return(
      <li className='menu-item'>
        <Link to={this.props.url}>{this.props.title}</Link>
        {this.state.sub_menu_items.length > 0 && <ul className='menu menu-subtree'>{this.state.sub_menu_items}</ul> }
      </li>
    );
  }
  
}

export default MainMenuItem;