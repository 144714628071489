import Paragraph from "./../types/paragraph";
import DefaultConfig from "./../global_config";
import ParagraphMitarbeiter from "./../components/Paragraphs/mitarbeiter";
import "../scss/paragraph-employee.scss";

const ParagraphRenderer = async (paragraph: any, i:number) => {
  let rendered_paragraph:any[] = [];
  let paragraph_data:any = null;
  
  if(paragraph.type === "paragraph--mitarbeiter"){
    paragraph_data = await RequestParagraph("mitarbeiter", paragraph.id, "include=field_mitarbeiter_bild");
    let img = DefaultConfig.DrupalServerUrl + paragraph_data.included[0].attributes.uri.url;
    let name = paragraph_data.data.attributes.field_name;
    rendered_paragraph.push(<ParagraphMitarbeiter key={i} img={img} name={name} />);
  } 
  
  return rendered_paragraph;
}

const RequestParagraph = (paragraphType: string, paragraph_uuid: string, paragraph_included_field?: string) => {
  if(typeof paragraph_included_field !== "undefined" && paragraph_included_field !== null && paragraph_included_field !== ""){
    return fetch(DefaultConfig.DrupalServerUrlFull + "/jsonapi/paragraph/" + paragraphType + "/" + paragraph_uuid + "?" + paragraph_included_field)
    .then(res => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
      }
  );
  }
  return fetch(DefaultConfig.DrupalServerUrlFull + "/jsonapi/paragraph/" + paragraphType + "/" + paragraph_uuid)
  .then(res => res.json())
  .then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
}

export default ParagraphRenderer;