/*
const DefaultConfig = {
  DrupalServerUrl: "http://localhost:8888",
  DrupalServerUrlFull: "http://localhost:8888/drupal_react/drupal",
  ReactServerUrl: "http://localhost:8888/"
}
*/

const DefaultConfig = {
  DrupalServerUrl: "https://drupalreact.ad7.dev",
  DrupalServerUrlFull: "https://drupalreact.ad7.dev",
  ReactServerUrl: "https://react.ad7.dev"
}




export default DefaultConfig;