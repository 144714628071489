import React from 'react';
import Router from "../util/Router";

class EventPage extends React.Component<{},{}>{
  constructor(props: any){
    super(props);
  }

  async componentDidMount(){
    const QueryRouter = new Router();
    await QueryRouter.ResolveUrl();

    console.log(QueryRouter.queryResult);
  }

  render(): JSX.Element{
    return(
      <div></div>
    );
  }
}

export default EventPage;